form.auth0-lock-widget {
  width: 100% !important;
  background: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding-top: 0px !important;

  font-family: "Akkurat S-GE",
  "Utopia S-GE",
  sans-serif !important;
}

form.auth0-lock-widget *{
  font-family: "Akkurat S-GE",
  "Utopia S-GE",
  sans-serif !important;
}

.auth0-lock-cred-pane {
  background: none !important;
  background-color: transparent !important;
}

.auth0-lock-widget .auth0-lock-tabs-container {
  display: none;
}

.auth0-lock-form {
  padding: 0px !important;
}

.auth0-lock-terms {
  display: none !important;
}

.auth0-lock-pane-separator + div p:first-child {
  display: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-center {
  padding: 0px !important;
}

.auth0-lock-pane-separator + div {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
}

.auth0-lock-input-email {
  order: 3;
  width: 100%;
}

.auth0-lock-input-email .auth0-lock-icon-box {
  display: none;
}

.auth0-lock-input-wrap-with-icon {
  padding-left: 0px !important;
}

.auth0-lock-input-show-password {
  order: 4;
  width: 100%;
}

.auth0-lock-input-given_name {
  order: 1;
  width: 48%;

}

@media (max-width: 1146px) {
  .auth0-lock-input-block.auth0-lock-input-given_name, .auth0-lock-input-block.auth0-lock-input-family_name {
    width: 100% !important;
  }
}

.auth0-lock-input-family_name {
  order: 2;
  width: 48%;
}

.auth0-lock-widget-container div.input-label {
  font-size: 16px !important;
  color: #545454 !important;
}

.auth0-lock-widget-container .auth0-lock-input-given_name input,
.auth0-lock-widget-container .auth0-lock-input-family_name input,
.auth0-lock-widget-container .auth0-lock-input-email input,
.auth0-lock-widget-container .auth0-lock-input-show-password input {
  padding: 15px !important;
  border: 1px solid #aaaaaa !important;
  line-height: 56px !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #545454 !important;
}

.auth0-lock-widget-container .auth0-lock-input-given_name,
.auth0-lock-widget-container .auth0-lock-input-family_name,
.auth0-lock-widget-container .auth0-lock-input-email,
.auth0-lock-widget-container .auth0-lock-input-show-password {
  margin-bottom: 22px !important;
}

.auth0-lock-widget-container div.input-label {
  font-weight: bold !important;
}

.auth0-lock-widget-container div.input-label {
  line-height: 24px;
  margin-bottom: 12px;
}

.action-buttons-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  width: 100% !important;
}


.action-buttons-box .auth-lock-social-buttons-pane {
}
.action-buttons-box .auth0-lock-submit {
  border-radius: 0px !important;
}

.auth0-label-submit {
  display: none !important;
}

.auth0-customlabel-submit:after {
  content: ' >';
}

[data-provider='linkedin'],
.action-buttons-box .auth0-lock-submit {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  border-radius: 0px !important;
}

.auth0-lock.auth0-lock .auth0-lock-social-button {
  height: auto !important;
  border: 2px solid #e6e6e6 !important;
}

.auth0-lock-social-button {
  background-color: transparent !important;
}

[data-provider='linkedin'] .auth0-lock-social-button-text,
.auth0-customlabel-submit {
  line-height: 24px !important;
  height: auto !important;
  font-size: 17px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

[data-provider='linkedin'] {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  width: 100% !important;
}

[data-provider='linkedin'] .auth0-lock-social-button-text {
  color: #3a3a3a !important;

  width: auto !important;
  padding-left: 12px !important;
  padding-right: inherit !important;
}

[data-provider='linkedin'] .auth0-lock-social-button-icon {
  /*   left: 11px !important; */
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
  top: inherit !important;
  left: inherit !important;
  background-position: center !important;
  background-size: contain !important;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3917_2897)'%3E%3Cpath d='M22.7283 0H2.27167C1.80179 0 1.35116 0.186657 1.01891 0.518909C0.686657 0.851161 0.5 1.30179 0.5 1.77167V22.2283C0.5 22.6982 0.686657 23.1488 1.01891 23.4811C1.35116 23.8133 1.80179 24 2.27167 24H22.7283C23.1982 24 23.6488 23.8133 23.9811 23.4811C24.3133 23.1488 24.5 22.6982 24.5 22.2283V1.77167C24.5 1.30179 24.3133 0.851161 23.9811 0.518909C23.6488 0.186657 23.1982 0 22.7283 0ZM7.65333 20.445H4.045V8.98333H7.65333V20.445ZM5.84667 7.395C5.43736 7.3927 5.03792 7.2692 4.69873 7.04009C4.35955 6.81098 4.09584 6.48653 3.94088 6.10769C3.78591 5.72885 3.74665 5.31259 3.82803 4.91145C3.90941 4.51032 4.1078 4.14228 4.39816 3.85378C4.68851 3.56529 5.05782 3.36927 5.45947 3.29046C5.86112 3.21165 6.27711 3.25359 6.65495 3.41099C7.03279 3.56838 7.35554 3.83417 7.58247 4.17481C7.80939 4.51546 7.93032 4.91569 7.93 5.325C7.93386 5.59903 7.88251 5.87104 7.77901 6.1248C7.67551 6.37857 7.52198 6.6089 7.32757 6.80207C7.13316 6.99523 6.90185 7.14728 6.64742 7.24915C6.393 7.35102 6.12067 7.40062 5.84667 7.395ZM20.9533 20.455H17.3467V14.1933C17.3467 12.3467 16.5617 11.7767 15.5483 11.7767C14.4783 11.7767 13.4283 12.5833 13.4283 14.24V20.455H9.82V8.99167H13.29V10.58H13.3367C13.685 9.875 14.905 8.67 16.7667 8.67C18.78 8.67 20.955 9.865 20.955 13.365L20.9533 20.455Z' fill='%230A66C2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3917_2897'%3E%3Crect width='24' height='24' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") !important;
}

/* Buttons */

.auth0-lock.auth0-lock
  .auth0-lock-social-button.auth0-lock-social-big-button:hover:not([disabled])
  .auth0-lock-social-button-text,
.auth0-lock.auth0-lock
  .auth0-lock-social-button.auth0-lock-social-big-button:focus:not([disabled])
  .auth0-lock-social-button-text {
  background-color: transparent !important;
}

.auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button:hover:not([disabled]) {
  background-color: #ccc !important;
  border: 2px solid #ccc !important;
}

.auth0-lock.auth0-lock .auth0-lock-submit:hover:not([disabled]) span {
  transform: none !important;
}

.auth0-lock.auth0-lock .auth0-lock-submit:hover:not([disabled]) {
  -webkit-filter: brightness(0.85) !important;
  filter: brightness(0.85) !important;
}

.action-buttons-box {
  margin-top: calc(30px - 12px);
}

.auth0-lock-password-strength {
  border: #3a3a3a !important;
  background-color: #3a3a3a !important;
  width: auto !important;
  max-width: 100% !important;
  padding-left: 12px !important;
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important;
  left: -1px !important;
}
.auth0-lock-password-strength::after {
  border-top-color: #3a3a3a !important;
  font-size: 14px !important;
}

/* Error */
.auth0-lock-password-strength > ul > li {
  color: #ff8488 !important;
  font-size: 14px !important;
}

.auth0-lock-password-strength li {
  font-size: 14px !important;
  line-height: 26px !important;
}

.auth0-lock-password-strength > ul > li > span {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' fill='%23FF8488'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.1072 10.9976L8.00159 8.89272L5.89275 11.0002L5 10.1079L7.1082 7.99977L5 5.89231L5.89275 5L8.00159 7.10747L10.1072 5.00254L11 5.89485L8.89434 8.00041L11 10.1053L10.1072 10.9976Z' fill='%233A3A3A'/%3E%3C/svg%3E") !important;
}

div.auth0-global-message-success, div.auth0-global-message-error{
  text-transform: none !important;
  text-align: left !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  padding: 29px !important;
  box-shadow: 10px 15px 80px rgba(0, 0, 0, 0.103393) !important;
  margin-bottom: 20px !important;
  line-height: 15px !important;
  font-weight: normal !important;
  color: #3A3A3A !important;
}

.auth0-lock-content-body-wrapper{
  border-radius: 0px !important;
}

.auth0-global-message-error {
  background-color: #E7BBBD !important;
}

.auth0-global-message-success {
  background-color: #C6CEDB !important;
}

/* Unknown state */
.auth0-lock-password-strength > ul > li > ul > li:not(.auth0-lock-checked) {
  color: #e6e6e6 !important;
}

.auth0-lock-password-strength > ul > li > ul > li:not(.auth0-lock-checked) > span {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' fill='%23E6E6E6'/%3E%3C/svg%3E");
}

.auth0-lock-password-strength > ul > li > ul > li.auth0-lock-checked > span,
.auth0-lock-password-strength > ul > li.auth0-lock-checked > span {
  color: #2fcc81 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7' fill='%232FCC81'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.65072 9.49032L5.49894 7.90223L4.66602 8.94339L7.84757 11.3553L11.5985 6.1783L10.5665 5.33398L7.65072 9.49032Z' fill='%233A3A3A'/%3E%3C/svg%3E") !important;
}

/* Backgrounds */
.auth0-lock-password-strength > ul > li > span,
.auth0-lock-password-strength > ul > li > ul > li:not(.auth0-lock-checked) > span,
.auth0-lock-password-strength > ul > li > ul > li.auth0-lock-checked > span {
  padding-top: 3px;
  padding-bottom: 3px;
  background-size: auto !important;
  background-position-y: 1px;
  background-position-x: -1px;
}

.auth0-lock-password-strength li span {
  padding-left: 25px !important;
}

/* Back button */
.auth0-lock-back-button-sge {
  cursor: pointer;
  border: 2px solid #e6e6e6 !important;
  height: auto !important;
  background-color: transparent !important;
  color: #3a3a3a !important;
  line-height: 24px !important;
  height: auto !important;
  font-size: 17px !important;
  font-weight: bold !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.auth0-lock-back-button-sge:hover {
  background-color: #ccc !important;
  border: 2px solid #ccc !important;
}

.auth0-lock-back-button-sge .icon {
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.41421 8.82843L0 7.41421L1.41421 6L7.41421 0L8.82843 1.41421L3.82843 6.41421H19V8.41421H3.82843L8.82843 13.4142L7.41421 14.8284L1.41421 8.82843Z' fill='%233A3A3A'/%3E%3C/svg%3E");
  width: 16px;
  height: 16px;

  padding-right: 13px;
  background-repeat: no-repeat;
  margin-top: 4px;
}

[visible='no'] {
  display: none;
}

.auth0-lock-alternative {
  order: 5;
  text-decoration: none !important;
  width: 100%;
  text-align: right !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.auth0-lock-alternative-link {
  color: #d90223 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.auth0-lock-alternative-link:hover {
  text-decoration: underline !important;
}

/* Reset password title */
[current-state='resetpassword'] .auth0-lock-form > div > p {
  text-align: left !important;
  padding-bottom: 25px;
}

/* Buttons */
.action-buttons-box .auth-lock-social-buttons-pane,
.action-buttons-box .auth0-lock-submit,
.auth0-lock-back-button-sge {
  width: calc(584px * 0.5 - 12px) !important;
}


/* Linked-in button only */
[only-linked-in-button="yes"] .action-buttons-box .auth-lock-social-buttons-pane {
    width: calc(584px * 0.5 + 7px) !important;
}

@media (max-width: 1146px) {
  [only-linked-in-button="yes"] .action-buttons-box .auth-lock-social-buttons-pane{
    width: 100% !important;
  }
}

/* Custom mobile treshold */
@media (max-width: 1400px) and (min-width: 1146px) {
  .action-buttons-box .auth-lock-social-buttons-pane,
  .action-buttons-box .auth0-lock-submit,
  .auth0-lock-back-button-sge {
    width: calc(415px * 0.5 - 12px) !important;
  }

  [data-provider='linkedin'] .auth0-lock-social-button-text,
  .auth0-customlabel-submit {
    font-size: 13px !important;
  }
}

@media (max-width: 1146px) {
  .action-buttons-box {
    flex-direction: column-reverse !important;
    
    width: 100% !important;
  }
}

@media (max-width: 1146px) {
  .action-buttons-box .auth-lock-social-buttons-pane,
  .action-buttons-box .auth0-lock-submit,
  .auth0-lock-back-button-sge {
    width: 100% !important;
  }

  .auth-lock-social-buttons-pane, .auth0-lock-back-button-sge{
    margin-top: 16px;
  }
}

/* Detected users */
.auth0-lock-alternative.auth0-lock-change-user,  .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative.auth0-lock-change-user p {
  text-align: left !important;
}

.auth0-lock-alternative.auth0-lock-change-user {
  background-color: #F6F7F8 !important;
  padding: 16px !important;
  width: max-content !important;
}

@media (max-width: 1146px) {
.auth0-lock-alternative.auth0-lock-change-user {
  width:auto !important;
  text-align: center !important;
}
}

.auth0-lock-alternative.auth0-lock-change-user *{
font-style: normal !important;
font-size: 14px !important;
color: #3A3A3A !important;
}

.auth0-lock-alternative.auth0-lock-change-user::first-line {
  font-weight: bold !important;
}

.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link-ccgid {
  color: black !important;
 
}

[hidden="true"]{
  display: none !important;
}

.auth0-lock.auth0-lock{
  z-index: 1000 !important;
}

.auth0-lock-cred-pane-internal-wrapper{
  height: auto !important;
}

.auth0-lock-center .auth0-lock-widget .auth0-lock-widget-container .auth0-lock-cred-pane {
  border-radius: 0px !important;
}

.auth0-lock-header-avatar{
  display: none !important;
}

.hidden-lock-wrapper * {
  display: none !important;
}